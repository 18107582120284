    import axios from "axios";
    import { useEffect, useState } from "react";
    import { useDispatch, useSelector } from "react-redux";
    import { Button, TextField } from '@mui/material';
    import { DataGrid } from '@mui/x-data-grid';
    import { ADD_STOCKS } from "../redux/constants";
    import { setStocks } from "../redux/stockActions";
    import { setAscast } from "../redux/ascastAction";
    import { setFinish } from "../redux/finishAction";
    import { setFinishout } from "../redux/outfinishAction";
    import SearchIcon from '@mui/icons-material/Search';
    import AddStockModal from "../components/AddStockModal";
    import ConfirmationModal from "../components/ConfirmationModal";
    import Typography from '@mui/material/Typography';
    import SuccessModal from "../components/SuccessModal";
    // import { formatDateToMonthDayYear, parseDateFromMonthDayYear } from "../components/DateFormatter" 
    import { useNavigate } from "react-router-dom";
    import { getAllStocks, fetchAllAsCastData, fetchAllFinishData, fetchFinishAllOutData } from "../services/apiService";

    const StandardStockList = () => {
        const finishData = useSelector(store => store.finish);
        const finishOutData = useSelector (store => store.finishout);
        const [showModal, setShowModal] = useState(false);
        const [successMessage, setSuccessMessage] = useState('');
        const [successModalOpen, setSuccessModalOpen] = useState(false);
        const stocks = useSelector(store => store.stocks);
        const [filter, setFilter] = useState('');
        const [rows, setRows] = useState([])
        const [editRowId, setEditRowId] = useState(null);
        const [editValues, setEditValues] = useState({});
        const [deleteRowId, setDeleteRowId] = useState(null);
        const [confirmModalOpen, setConfirmModalOpen] = useState(false);

        const dispatch = useDispatch();
        const navigate = useNavigate();
        const [totalQuantities, setTotalQuantities] = useState({});

        const finishOutMap = finishOutData.reduce((map, item) => {
            if (!map[item.finish_id]) {
                map[item.finish_id] = 0;
            }
            map[item.finish_id] += item.quantity_out;
            return map;
        }, {});
        
        // Subtract the quantity from finishData based on finish_id
        const updatedFinishData = finishData.map(item => {
            const quantityOut = finishOutMap[item.finish_id] || 0;
            return {
                ...item,
                quantity: item.quantity - quantityOut
            };
        });

        const fetchData = async () => {
            try {
                const stocks = await getAllStocks();
                dispatch(setStocks(stocks));
                const finish = await fetchAllFinishData();
                dispatch(setFinish(finish));
                const finishOut = await fetchFinishAllOutData();
                dispatch(setFinishout(finishOut));
                const asCast = await fetchAllAsCastData();
                dispatch(setAscast(asCast));
        
                const quantities = asCast.reduce((acc, item) => {
                    if (item.standard_id) { // Check if standard_id exists
                        if (acc[item.standard_id]) {
                            acc[item.standard_id] += item.quantity;
                        } else {
                            acc[item.standard_id] = item.quantity;
                        }
                    }
                    return acc;
                }, {});
        
                console.log("Total Quantities:", quantities); // Debugging line
                setTotalQuantities(quantities);
    
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        const renderEditableCell = (params, editRowId, editValues, handleInputChange) => {
            const { id, field, value } = params;
        
            if (editRowId === id) {
                return (
                    <TextField
                        name={field}
                        value={editValues[field] || ''}
                        onChange={(e) => handleInputChange(e, id)}
                        onKeyDown={(e) => e.stopPropagation()}
                    />
                );
            } else {
                return value;
            }
        };
        const handleInputChange = (e) => {
            const { name, value } = e.target;
            setEditValues({ ...editValues, [name]: value });
        };
        
        function handleOnRowClick(event, rowData) {
            // Check if the clicked element is an input field or button
            if (event.target.tagName.toLowerCase() === 'input' || event.target.tagName.toLowerCase() === 'button') {
                // If it's an input field or button, don't execute further actions
                return;
            }
            const { id } = rowData; // Extracting standard stock id
            navigate('/as-cast', { state: { standard_id: id  } }); // Passing standard_id to the next page
        }

        const handleEditClick = (row) => {
            setEditRowId(row.id);
            setEditValues(row);
        };

        const handleSaveClick = async () => {
            try {
        
                const updatedValues = {
                    ...editValues
                    // date: formattedDate, 
                };
                await axios.put(`http://178.128.110.90/api/standard_stock/${editRowId}`, updatedValues);
                setRows(rows.map((row) => (row.id === editRowId ? updatedValues : row)));
                setEditRowId(null);
                
                setEditValues({});

                fetchData();
                setSuccessMessage('Stock successfully updated.');
                setSuccessModalOpen(true);
            } catch (error) {
                console.error("Failed to save the changes:", error);
            }
        };

        const handleCancelClick = () => {
            setEditRowId(null);
            setEditValues({});
        };
        const handleDelete = async (rowId) => {
            setDeleteRowId(rowId);
            setConfirmModalOpen(true);
        };
        function handleOnFilterChange(e) {
            setFilter(e.target.value);
        }

        const confirmDelete = async () => {
            try {
                await axios.put(`http://178.128.110.90/api/standard_stock/delete/${deleteRowId}`);
                const updatedStocks = stocks.filter((stock) => stock.id !== deleteRowId);
                dispatch(setStocks(updatedStocks));
                setRows(updatedStocks);
                setDeleteRowId(null);
                setConfirmModalOpen(false);
            } catch (error) {
                console.error("Failed to delete the row:", error);
            }
        };
    
        useEffect(() => {
            fetchData();
        }, [dispatch]);

        useEffect(() => {
            setRows(stocks);
        }, [stocks]);

        useEffect(() => {
            const filteredStocks = stocks.filter(stock => (
                stock.size.toString().toLowerCase().includes(filter.toLowerCase()) ||
                stock.hl.toString().toLowerCase().includes(filter.toLowerCase())
            ));
            setRows(filteredStocks);
        }, [filter, stocks]);
    
        function showAddStockModal() {
            setShowModal(true);
        }
        function hideAddStockModal() {
            setShowModal(false);
        }

        const cols = [
            {
                field: 'size',
                headerName: (
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Size</Typography>
                ),
                minWidth: 240,
                renderCell: (params) => {
                    let displayValue;
                    const splitValues = params.id === editRowId ? editValues.size.split(' x ') : params.value.split(' x ');
            
                    if (splitValues.length === 1) {
                        // Case: "12" -> "12" diameter"
                        const [diameter] = splitValues;
                        displayValue = `${diameter}" diameter`;
                    } else if (splitValues.length === 2) {
                        // Case: "12 x 10" -> "12" diameter x 10" pitch"
                        const [diameter, pitch] = splitValues;
                        displayValue = `${diameter}" diameter x ${pitch}" pitch`;
                    } else if (splitValues.length === 3) {
                        // Case: "18 x 1 1/2 x 9" -> "18 x 1 1/2" diameter x 9" pitch"
                        const [diameter, intermediate, pitch] = splitValues;
                        displayValue = `${diameter} x ${intermediate}" diameter x ${pitch}" pitch`;
                    } else {
                        // Default case if something unexpected happens
                        displayValue = params.value;
                    }
            
                    if (params.id === editRowId) {
                        // Return the editable cell during editing
                        return renderEditableCell(params, editRowId, editValues, handleInputChange);
                    }
            
                    return displayValue;
                },
            },        
            {
                field: 'hl',
                headerName: (
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>HL</Typography>
                ),
                minWidth: 170,
                renderCell: (params) => (
                    <div>
                        {renderEditableCell(params, editRowId, editValues, handleInputChange)} "
                    </div>
                ),
            },
            {
                field: 'shaft_size',
                headerName: (
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>As Cast Wt.</Typography>
                ),
                minWidth: 170,
                renderCell: (params) =>(
                    <div>
                        {renderEditableCell(params, editRowId, editValues, handleInputChange)} kg
                    </div>
                ),
            },
          {
                field: 'quantity',
                headerName: (
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>As Cast Stock</Typography>
                ),
                minWidth: 170,
                renderCell: (params) => {
                    return (
                        <div>
                            {totalQuantities[params.row.id] || 0}
                        </div>
                    );
                },
            },
           {
                field: 'finished_quantity',
                headerName: (
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Finished Stock</Typography>
                ),
                minWidth: 170,
                renderCell: (params) => {
                    // Find the updated data for the current row by standard_id
                    const updatedRow = updatedFinishData.find(row => row.standard_id === params.row.id);
                    console.log("updated row", updatedRow);
            
                    // Display the updated quantity
                    return (
                        <div>
                            {updatedRow ? updatedRow.quantity : 0}
                        </div>
                    );
                },
            },
            {
                field: 'actions',
                headerName: (
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: "system-ui", fontSize: "17px" }}>Actions</Typography>
                ),
                minWidth: 200,
                renderCell: (params) =>
                    editRowId === params.row.id ? (
                        <div>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{ backgroundColor: 'green', '&:hover': { backgroundColor: 'darkgreen' } }}
                                style={{ marginRight: 8 }}
                                onClick={handleSaveClick}
                            >
                                Save
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{ backgroundColor: 'darkgray', '&:hover': { backgroundColor: 'gray' } }}
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    ) : (
                        <div>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ backgroundColor: 'primary', '&:hover': { backgroundColor: 'blue' } }}
                            style={{ marginRight: 8 }}
                            onClick={() => handleEditClick(params.row)}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ backgroundColor: 'darkred', '&:hover': { backgroundColor: 'red' } }}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </Button>
                    </div>
                    ),
            },
        ];
        
        return (
            <div className="page">
                {showModal && <AddStockModal hideAddStockModal={hideAddStockModal} actionType={ADD_STOCKS} />}
                
                <div className="stock-list">
                    {successMessage && (
                        <SuccessModal open={successModalOpen} handleClose={() => setSuccessModalOpen(false)} message={successMessage} duration={1000} />
                    )}
                   <div className="header-container">
                        <p className="header-title">STANDARD PROPELLER</p>
                    </div>
                    <div className="stock-list__toolbar">
                        <div className="stock-list__toolbar__search">
                            <SearchIcon className="stock-list__toolbar__search__icon" />
                            <input
                                type="text"
                                placeholder="Search here"
                                value={filter}
                                onChange={(e) => handleOnFilterChange(e)}
                            />
                        </div>
                        <div>
                            <button className="btn" onClick={showAddStockModal}>Add Stock</button>
                        </div>
                    </div>
                    <div className="employee-list__table">
                        <DataGrid
                            rows={rows}
                            columns={cols}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            disableRowSelectionOnClick
                            autoHeight
                            onRowClick={(params, event) => { // Pass event object as the second argument
                                handleOnRowClick(event, params.row); // Pass event as the first argument
                            }}
                            style={{ backgroundColor: 'white' }}
                        />
                    </div>
                </div>
                <ConfirmationModal
                    open={confirmModalOpen}
                    handleClose={() => setConfirmModalOpen(false)}
                    handleConfirm={confirmDelete}
                />
            </div>
        );
    }    

    export default StandardStockList;