import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
// import { addAscast } from "../../redux/ascastAction";
import { useLocation } from "react-router-dom";
import { addBushings } from "../../redux/bushingsAction";

const BushingsInfo = (props) => {
    const dispatch = useDispatch();
    const today = new Date().toISOString().split('T')[0];

    const location = useLocation();
    const { bushing_id } = location.state || {};
    // console.log("check", bushing_id);


    const [bushingsInfo, setBushingsInfo] = useState({
        shaft_size: '',
        quantity: '',
        delete_flg: '0',
        bushings_date: today
    });

    useEffect(() => {
        if (props.bushingsData) {
            setBushingsInfo({
                ...props.bushingsData,
                delete_flg: '0'
            });
        }
    }, [props.bushingsData]);

    function handleChange(e) {
        const { name, value } = e.target;
        setBushingsInfo(prevInfo => ({
            ...prevInfo,
            [name]: value
        }));
    }

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const requestData = {
                ...bushingsInfo,
                bushing_id: bushing_id // Corrected
            };
            console.log("Request data: ", requestData); // Log request data
    
            const response = await axios.post('http://178.128.110.90/api/bushings', requestData);
            dispatch(addBushings(response.data.bushings));
            props.handleCancel(); // Optionally, you can call a function to handle post-submission actions
        } catch (err) {
            console.error("Error submitting form: ", err);
        }
    }
    // Check if all required fields except `standard_id` have truthy values
    const isFormValid = () => {
        const { shaft_size, quantity, bushings_date } = bushingsInfo;
        return shaft_size && quantity && bushings_date;
    };

    return (
        <div className="stock-info">
            <h3 className="stock-info__title">BUSHINGS IN INFO</h3>
            <hr className="stock-info__hr"/>
            <form className="stock-info__form" onSubmit={handleSubmit}>
                <div className="input-field">
                    <label htmlFor="bushings_date">Date:</label>
                    <input 
                        type="date" 
                        id="bushings_date" 
                        name="bushings_date" 
                        value={bushingsInfo.bushings_date} 
                        min={today} 
                        onChange={handleChange} 
                        required 
                    />
                </div>
                <div className="input-field">
                    <label htmlFor="shaft">Size:</label>
                    <div className="input-container">
                        <input 
                            type="text" 
                            id="shaft_size" 
                            name="shaft_size" 
                            value={bushingsInfo.shaft_size} 
                            onChange={handleChange} 
                            required 
                        />
                        <span className="inches-label">"</span>
                    </div>
                </div>
                <div className="input-field">
                <label htmlFor="quantity">Quantity:</label>
                    <div className="input-container">
                        <select
                            id="quantity"
                            name="quantity"
                            value={bushingsInfo.quantity}
                            onChange={handleChange}
                            required
                            className="quantity-select" // Added class for styling
                        >
                            <option value="" disabled>Select quantity</option>
                            {[...Array(10).keys()].map(i => (
                                <option key={i + 1} value={i + 1}>{i + 1}</option>
                            ))}
                        </select>
                        <span className="custom-arrow"></span> {/* Custom arrow for dropdown */}
                    </div>
                </div>
                <div className="stock-info__form__btns">
                    <button type="button" onClick={props.handleCancel} className="btn btn--text">Cancel</button>
                    <button type="submit" className="btn" disabled={!isFormValid()}>Save</button>
                </div>
            </form>
        </div>
    );
}

export default BushingsInfo;
